<template>
    <div :class="heroClasses" :style="backgroundColors">
        <div class="tabbed-searchbox-hero__bg" :style="backgroundColors"></div>
        <picture v-if="bg">
            <NuxtImg
                :src="bg"
                :alt="alt"
                provider="storyblok"
                format="avif"
                height="1000"
                class="tabbed-searchbox-hero__image"
                loading="eager"
            />
        </picture>
        <div class="container">
            <div class="tabbed-searchbox-hero__main">
                <div v-if="showTitles" class="tabbed-searchbox-hero__titles">
                    <div class="tabbed-searchbox-hero__title">{{ activeTabContent.Title }}</div>
                    <div class="tabbed-searchbox-hero__subtitle">{{ activeTabContent.Subtitle }}</div>
                </div>
                <div v-if="tabs?.length > 1" class="tabbed-searchbox-hero__tabs">
                    <Tabs :active-tabs="tabs" :active-tab="activeTab" @set-active-tab="setActiveTab" />
                </div>
                <div class="tabbed-searchbox-hero__form">
                    <Form
                        :blok="blok"
                        :airports="airports"
                        :form-action="formActions[activeTab]"
                        :active-tab="activeTab"
                        @submit-form="submitForm"
                    >
                        <template #formLegacyFields>
                            <slot name="legacyFields" />
                        </template>
                    </Form>
                </div>
                <div v-if="showExtras" class="tabbed-searchbox-hero__extra">
                    <Extra
                        :key-selling-points="blok.keySellingPoints"
                        :key-selling-points-text-color="keySellingPointsTextColor"
                        :show-feefo-reviews="blok.showFeefoReviews"
                        :feefo-image-url="feefoImageUrl"
                        @scroll-to-reviews="emit('scrollToReviews')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Form from './Form.vue';
import Tabs from './Tabs.vue';
import Extra from './Extra.vue';

const {
    blok,
    airports,
    formActions = [],
    feefoImageUrl = '',
} = defineProps<{
    blok;
    airports;
    formActions;
    feefoImageUrl?;
}>();

const emit = defineEmits(['submitForm', 'scrollToReviews', 'setActiveTab']);

// TABS
const tabs = blok.formTabs;
const activeTab = ref(blok.defaultTab);
const setActiveTab = (tab) => {
    activeTab.value = tab;
    emit('setActiveTab', tab);
};

const activeTabContent = computed(() => blok.formTabs.find((tab) => tab.tabType === activeTab.value));
const showTitles = computed(() => activeTabContent.value && blok.sectionType === 'hero');
const showExtras = computed(() => blok?.keySellingPoints?.length || blok.showFeefoReviews);
const bg = computed(() => activeTabContent?.value?.backgroundImage?.filename);
const alt = computed(() => activeTabContent?.value?.backgroundImage?.alt);
const heroClasses = computed(() => `tabbed-searchbox-hero tabbed-searchbox-hero--${blok.sectionType}`);
const keySellingPointsTextColor = computed(() => blok?.keySellingPointsTextColor?.color || '#FFF');

const backgroundColors = computed(() => {
    if (bg.value) return '';
    return {
        background: `linear-gradient(to right, ${blok.backgroundColorStart?.color ? blok.backgroundColorStart?.color : '#6b3084'}, ${blok.backgroundColorEnd?.color ? blok.backgroundColorEnd?.color : '#6b3084'})`,
    };
});

const submitForm = ({ formData, form }) => {
    emit('submitForm', { formData, form });
};
</script>

<style scoped lang="postcss">
.tabbed-searchbox-hero {
    @apply py-8 lg:py-24 relative px-4 md:px-0;
    &--section {
        @apply py-8;
    }
    &__bg {
        @apply hidden;
    }
    &__image {
        @apply absolute top-0 bottom-0 left-0 right-0 w-full h-full object-cover object-[0] z-[0];
    }
    &__main {
        @apply relative;
    }
    &__titles {
        @apply mb-8 text-center text-white;
    }
    &__title {
        @apply text-2xl md:text-4xl font-bold;
    }
    &__subtitle {
        @apply text-base;
    }
    &__form {
        @apply rounded-lg bg-white;
        .tabbed-searchbox-hero--section & {
            @apply border border-wl-base-borders rounded-lg;
            box-shadow: 0px 8px 16px 0px rgba(43, 46, 49, 0.04);
        }
    }
    &__tabs {
        & + .tabbed-searchbox-hero__form {
            @apply rounded-t-none;
        }
    }
    &__extra {
        @apply flex flex-col md:flex-row items-start mt-6;
    }
}
.tab-btn {
    @apply flex flex-col items-center p-2 gap-2 text-[#6C3084];
    &:first-child {
        @apply rounded-tl-lg;
    }
    &:last-child {
        @apply rounded-tr-lg;
    }
    &.is-active {
        @apply bg-[#6C3084] text-white;
    }
    &__icon {
        @apply h-8 w-8;
    }
    &__text {
        @apply text-base font-bold;
    }
}
</style>
