<template>
    <div class="error-message">
        <div class="error-icon">
            <NuxtIcon name="ri:error-warning-line" class="text-red-800" />
        </div>
        <div v-if="Array.isArray(html)">
            <span v-for="(msg, index) in html" :key="index">
                {{ msg }}
            </span>
        </div>
        <span v-if="message">{{ message }}</span>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-else-if="html && !Array.isArray(html)" v-html="html" />
    </div>
</template>

<script lang="ts" setup>
import type VueI18n from 'vue-i18n';

interface ErrorMessageProps {
    html?: string | string[] | VueI18n.TranslateResult;
    message?: string | VueI18n.TranslateResult;
}

withDefaults(defineProps<ErrorMessageProps>(), {
    html: '',
    message: '',
});
</script>

<style lang="postcss" scoped>
.error-message {
    @apply text-sm bg-white py-2 pl-2 pr-4 flex rounded-lg items-center w-full;
    z-index: 2;

    box-shadow: 0 0 24px rgba(172, 177, 193, 0.16);
}
.error-icon {
    @apply rounded-lg bg-red-100 p-2 mr-2 w-8 h-8 flex content-center items-center;
}
</style>
