<template>
    <button class="icon-button" :disabled="disabled" :aria-label="ariaLabel">
        <i v-if="iconName.length > 0" class="icon-button__wrap">
            <NuxtIcon :name="iconName" size="24" />
        </i>
        <slot />
    </button>
</template>

<script setup lang="ts">
defineProps({
    iconName: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    ariaLabel: {
        type: String,
        default: '',
    },
});
</script>

<style lang="postcss" scoped>
.icon-button {
    @apply flex items-center;
}

.icon-button__wrap {
    @apply flex items-center justify-center w-6 h-6 mr-2;
}
</style>
