<template>
    <form
        :key="formKey"
        :action="formAction"
        method="POST"
        class="search-form"
        :class="searchFormClass"
        @submit.prevent="submitForm"
    >
        <slot name="formLegacyFields"></slot>

        <div v-if="showHotelToggler" class="search-form__control search-form__control--hotel-toggler">
            <HotelToggler v-model:hotel-return="formData.hotelReturn" />
        </div>

        <div v-if="showAirport" class="search-form__control search-form__control--airport">
            <SearchAirport
                :key="airportInputKey"
                name="search"
                :country="availableCountry"
                :label="$t(`searchWidget.${activeTab}.airportLabel`)"
                :placeholder="$t(`searchWidget.${activeTab}.airportPlaceholder`)"
                :items="airports.airportList"
                :with-countries="airports.withCountries"
                :value="formData.location"
                :error="v$.location.$error"
                :error-message="errorMessages.location"
                @update:value="(value: any) => updateLocation(value)"
            />
        </div>

        <div v-if="showTerminalsSelector" class="search-form__control search-form__control--terminal">
            <span class="search-form__control-label">
                {{ $t(`searchWidget.${activeTab}.terminalLabel`) }}
            </span>
            <DropdownWrapper
                v-model="formData.terminal"
                v-model:dropdown-opened="isTerminalDropDownOpened"
                v-on-click-outside="() => (isTerminalDropDownOpened = false)"
                match-width
                required
                label="Select terminal"
                :error="v$.terminal.id.$error"
                :error-message="errorMessages.terminal"
                :items="availableTerminals"
                @update:model-value="(value: any) => updateTerminal(value)"
            />
        </div>

        <div class="search-form__control search-form__control--entryDate">
            <DatePickerWrapper
                v-model:dropdown-opened="isEntryDatePickerOpened"
                date-label="Date"
                :min-date="currentDate()"
                :date="formData.dates.start.date"
                :error="v$.dates.start.date.$error"
                :error-message="errorMessages.dates.start.date"
                :label="$t(`searchWidget.${activeTab}.entryDateLabel`)"
                name="entryDate"
                @date-change="onStartDateChange"
                @blur="() => {}"
                @focus="() => {}"
            />
        </div>

        <div v-if="showEntryTime" class="search-form__control search-form__control--entryTime">
            <TimePickerWrapper
                v-model:dropdown-opened="isEntryTimePickerOpened"
                :date="formData.dates.start.date"
                :time="formData.dates.start.time"
                :error="v$.dates.start.time.$error"
                :error-message="errorMessages.dates.start.time"
                :label="$t(`searchWidget.${activeTab}.entryTimeLabel`)"
                label-desktop="Entry from:"
                :is-entry="true"
                :time-format="blok.timeFormat"
                :step="blok.timePickerSteps"
                @time-change="onStartTimeChange"
            />
        </div>

        <div v-if="showExitDate" class="search-form__control search-form__control--exitDate">
            <DatePickerWrapper
                v-model:dropdown-opened="isExitDatePickerOpened"
                date-label="Date"
                :date="formData.dates.end.date"
                :min-date="formData.dates.start.date ? formData.dates.start.date : currentDate()"
                :error="v$.dates.end.date.$error"
                :error-message="errorMessages.dates.end.date"
                :label="$t(`searchWidget.${activeTab}.exitDateLabel`)"
                name="exitDate"
                calendar-position="right"
                @date-change="onEndDateChange"
                @blur="() => {}"
                @focus="() => {}"
            />
        </div>

        <div v-if="showExitTime" class="search-form__control search-form__control--exitTime">
            <TimePickerWrapper
                v-model:dropdown-opened="isExitTimePickerOpened"
                :date="formData.dates.end.date"
                :time="formData.dates.end.time"
                :min-time="minExitTime"
                :error="v$.dates.end.time.$error"
                :error-message="errorMessages.dates.end.time"
                :label="$t(`searchWidget.${activeTab}.exitTimeLabel`)"
                label-desktop="Exit before:"
                :time-format="blok.timeFormat"
                :step="blok.timePickerSteps"
                @time-change="onEndTimeChange"
            />
        </div>

        <div v-if="showGuestsSelector" class="search-form__control search-form__control--guests">
            <CustomersSelectorWrapper
                v-model:dropdown-opened="isCustomersSelectorOpened"
                :customers="formData.customers"
                :label="$t(`searchWidget.${activeTab}.guestLabel`)"
                @update-customers="updateCustomers"
            />
        </div>

        <div v-if="showRoomsSelector" class="search-form__control search-form__control--rooms">
            <span class="search-form__control-label">
                {{ $t(`searchWidget.${activeTab}.roomsLabel`) }}
            </span>
            <DropdownWrapper
                v-model="formData.rooms"
                v-model:dropdown-opened="isRoomsDropDownOpened"
                v-on-click-outside="() => (isRoomsDropDownOpened = false)"
                match-width
                required
                label="Select"
                :error="v$.rooms.$error"
                :error-message="errorMessages.rooms"
                :items="roomTypeList"
                @update:model-value="(value: any) => updateRooms(value)"
            />
        </div>

        <div v-if="showDiscount" class="search-form__control search-form__control--discount">
            <SearchInput
                ref="discountWrapper"
                :label="$t(`searchWidget.${activeTab}.discountLabel`)"
                :placeholder="$t(`searchWidget.${activeTab}.discountPlaceholder`)"
                name="promocode"
                :value="formData.discount ?? ''"
                class="flex-1 !p-0"
                @update:value="(value: any) => updateDiscount(value)"
            />
        </div>
        <button type="submit" :disabled="submitDisabled" class="search-submit">Search</button>
    </form>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { vOnClickOutside } from '@vueuse/components';
import { helpers, minLength, required } from '@vuelidate/validators';
import { useTabbedSearchStore } from '../../../../apps/_base/store/tabbedSearch';
import { useIsMobileView } from '~ui/composables/isMobileView';
import screens from '#tailwind-config/theme/screens';

import {
    differenceInDays,
    parse,
    addMinutes,
    isAfter,
    isBefore,
    parseISO,
    isEqual,
    zonedTimeToUtc,
} from './date-utilities';
import { terminalsList, roomTypeList, TABS } from './constants';

const availableCountry = computed(() => {
    return [TABS.AIRPORT_LOUNGES, TABS.HOTEL_AND_PARKING].includes(activeTab) ? 'uk' : '';
});

// Traffic Splitter
import {
    isMigrationActive,
    checkMigrationVariant,
    redirectToParkingPropel,
    redirectToLoungePropel,
} from './traffic-splitter';

// Components
import SearchAirport from './components/SearchAirport.vue';
import CustomersSelectorWrapper from './components/CustomersSelector/CustomersSelectorWrapper.vue';
import TimePickerWrapper from './components/TimePicker/TimePickerWrapper.vue';
import DropdownWrapper from './components/Common/DropdownWrapper.vue';
import DatePickerWrapper from './components/DatePicker/DatePickerWrapper.vue';
import SearchInput from './components/SearchInput.vue';
import HotelToggler from './components/HotelToggler.vue';

// Init
const {
    blok,
    airports,
    terminals = terminalsList,
    formAction,
    activeTab = 'airport-parking',
} = defineProps<{
    blok;
    airports;
    terminals?;
    formAction;
    activeTab;
}>();

const emit = defineEmits(['submitForm']);

const formKey = ref(1);
const airportInputKey = ref(1);

const tabbedSearchStore = useTabbedSearchStore();

const { isMobileView } = useIsMobileView(screens.lg);

// Migration
const config = useRuntimeConfig();
const STORYBLOK_FOLDER = config?.public?.STORYBLOK_FOLDER;

const migrationKey = computed(() => {
    let migrationKey = '';
    if (STORYBLOK_FOLDER === 'l4') {
        if ([TABS.AIRPORT_PARKING, TABS.PORT_PARKING].includes(activeTab)) {
            migrationKey = '_parking-only';
        }
    }
    if (STORYBLOK_FOLDER == 'sps') {
        if (activeTab === TABS.AIRPORT_PARKING) {
            migrationKey = '_airportparking';
        }
        if (activeTab === TABS.AIRPORT_LOUNGES) {
            migrationKey = '_airportlounge';
        }
    }
    return migrationKey;
});

const formAirport = inject('airport', '');
const propelProductSKU = inject<string>('propelProductSKU', '');

const availableTerminals = computed(() => {
    const activeAirportId = +formData.value.location.id;
    const terminals = terminalsList.filter((terminal) => terminal.airportId === activeAirportId);
    return terminals.length ? [...terminals, { id: 'dontKnow', name: "Don't know", airportId: 0 }] : terminals;
});

const defaultAirport = computed(() => {
    let injectedCode = '';
    if (formAirport.value) {
        injectedCode = formAirport?.content
            ? formAirport?.content?.code?.toUpperCase()
            : formAirport?.code?.toUpperCase();
    }
    const defaultCode = blok.defaultAirportCode || injectedCode;
    return airports.airportList.flat().find((airport) => airport.code === defaultCode);
});

// Dropdowns open state
const isTerminalDropDownOpened = ref(false);
const isEntryDatePickerOpened = ref(false);
const isEntryTimePickerOpened = ref(false);
const isExitDatePickerOpened = ref(false);
const isExitTimePickerOpened = ref(false);
const isCustomersSelectorOpened = ref(false);
const isRoomsDropDownOpened = ref(false);
const discountWrapper = ref(null);

// Fields visibility
const showAirport = computed(() => blok.showAirport);
const showEntryTime = computed(() => activeTab !== TABS.HOTEL_AND_PARKING);
const showExitDate = computed(() => activeTab !== TABS.AIRPORT_LOUNGES);
const showExitTime = computed(() => ![TABS.HOTEL_AND_PARKING, TABS.AIRPORT_LOUNGES].includes(activeTab));
const showDiscount = computed(() => blok.showDiscount);
const showTerminalsSelector = computed(() => activeTab !== TABS.HOTEL_AND_PARKING && !!availableTerminals.value.length);
const showGuestsSelector = computed(() => activeTab !== TABS.HOTEL_AND_PARKING);
const showRoomsSelector = computed(() => activeTab === TABS.HOTEL_AND_PARKING);
const showHotelToggler = computed(() => activeTab === TABS.HOTEL_AND_PARKING && STORYBLOK_FOLDER === 'l4');
const submitDisabled = computed(() => ![TABS.AIRPORT_PARKING, TABS.PORT_PARKING].includes(activeTab));

// Form data
const formData = ref({
    location: {
        name: defaultAirport.value ? defaultAirport.value.name : tabbedSearchStore.getAllData.location.name,
        code: defaultAirport.value ? defaultAirport.value.code : tabbedSearchStore.getAllData.location.code,
        id: defaultAirport.value ? defaultAirport.value.id : tabbedSearchStore.getAllData.location.id,
    },
    dates: {
        start: {
            date: tabbedSearchStore.getAllData.dates.start.date || '',
            time: tabbedSearchStore.getAllData.dates.start.time || '',
        },
        end: {
            date: tabbedSearchStore.getAllData.dates.end.date || '',
            time: tabbedSearchStore.getAllData.dates.end.time || '',
        },
    },
    terminal: tabbedSearchStore.getAllData.terminal || {
        id: '',
        name: '',
        airportId: '',
    },
    customers: {
        adult: tabbedSearchStore.getAllData.passengers.adult || 0,
        child: tabbedSearchStore.getAllData.passengers.child || 0,
        children_ages: tabbedSearchStore.getAllData.passengers.children_ages || [],
    },
    rooms: tabbedSearchStore.getAllData.rooms || 0,
    discount: tabbedSearchStore.getAllData.discount || '',
    hotelReturn: tabbedSearchStore.getAllData.hotelReturn || false,
});

// Update formData from query
const { query } = useRoute();
if (query.code) {
    formData.value.discount = query.code;
    tabbedSearchStore.updateDiscount(query.code);
}

if (query.airport) {
    const airportFromQuery = airports.airportList
        .flat()
        .find((airport) => airport.code === query.airport.toUpperCase());
    if (airportFromQuery) {
        const locationFromQuery = {
            name: airportFromQuery.name,
            code: airportFromQuery.code,
            id: airportFromQuery.id,
        };
        formData.value.location = locationFromQuery;
        tabbedSearchStore.updateLocation(locationFromQuery);
    }
}

if (query.terminal) {
    const terminalFromQuery = terminalsList.find((terminal) => terminal.id === +query.terminal);
    if (terminalFromQuery) {
        const terminalFromQueryData = {
            id: terminalFromQuery.id,
            name: terminalFromQuery.name,
            airportId: terminalFromQuery.name,
        };
        formData.value.terminal = terminalFromQueryData;
        tabbedSearchStore.updateTerminal(terminalFromQueryData);
    }
}

// Validation rules
const rules = computed(() => {
    const rules: any = {
        location: {
            name: {
                required: helpers.withMessage('Please, select airport', required),
                minLength: minLength(3),
            },
        },
        dates: {
            start: {
                date: {
                    required: helpers.withMessage('Please, select entry date', required),
                },
                time: {
                    required: helpers.withMessage('Please, select entry time', required),
                },
            },
            end: {
                date: {
                    required: helpers.withMessage('Please, select exit date', required),
                },
                time: {
                    required: helpers.withMessage('Please, select exit time', required),
                },
            },
        },
    };
    if (showTerminalsSelector.value) {
        rules.terminal = {
            id: {
                required: helpers.withMessage('Please, select terminal', required),
            },
        };
    }
    if (showRoomsSelector.value) {
        rules.rooms = {
            required: helpers.withMessage('Please, select rooms', required),
        };
    }
    return rules;
});

const errorMessages = computed(() => {
    return {
        location: v$.value.location.$errors.map((e) => e.$message)[0],
        dates: {
            start: {
                date: v$.value?.dates.start.date?.$errors?.map((e) => e.$message)[0],
                time: v$.value?.dates.start.time?.$errors?.map((e) => e.$message)[0],
            },
            end: {
                date: v$.value?.dates.end.date?.$errors?.map((e) => e.$message)[0],
                time: v$.value?.dates.end.time?.$errors?.map((e) => e.$message)[0],
            },
        },
        rooms: v$.value?.rooms?.$errors?.map((e) => e.$message)[0],
        terminal: v$.value?.terminal?.id?.$errors?.map((e) => e.$message)[0],
    };
});

const v$ = useVuelidate(rules, formData);

// Date / time helpers
const datesArePast = computed(() => {
    const {
        dates: {
            start: { date: startDate, time: startTime },
            end: { date: endDate, time: endTime },
        },
    } = formData.value;
    const startDateTime = `${startDate} ${startTime}`;
    const endDateTime = `${endDate} ${endTime}`;

    const currentDate = new Date();

    const zonedEntryDate = zonedTimeToUtc(parseISO(startDateTime), 'Europe/London');
    const zonedExitDate = zonedTimeToUtc(parseISO(endDateTime), 'Europe/London');
    return (
        (!!startDate && !!startTime && isBefore(zonedEntryDate, currentDate)) ||
        (!!startDate && !!endTime && isBefore(zonedExitDate, currentDate))
    );
});

const today = ref(new Date());
//in case if user open the page and leave it to the next day, the date should be updated
const currentDate = () => {
    if (differenceInDays(today.value, new Date()) > 0) {
        today.value = new Date();
    }
    return today.value;
};

const entryDateTimeBeforeExitDateTime = computed(() => {
    const entryDate = parseISO(`${formData.value.dates.start.date} ${formData.value.dates.start.time || ''}`);
    const exitDate = parseISO(`${formData.value.dates.end.date} ${formData.value.dates.end.time || ''}`);
    return isAfter(entryDate, exitDate) || isEqual(entryDate, exitDate);
});

const minExitTime = computed(() => {
    const minDate = new Date(new Date(formData.value.dates.start.date).toDateString());
    const endDate = new Date(new Date(formData.value.dates.end.date).toDateString());
    if (differenceInDays(minDate, endDate) !== 0) {
        return '';
    }
    return addMinutes(parse(formData.value.dates.start.time, 'HH:mm', endDate), 60);
});

const resetExitDateTime = () => {
    formData.value.dates.end.date = '';
    formData.value.dates.end.time = '';
    tabbedSearchStore.updateEndDates('', '');
};

const resetDates = () => {
    resetExitDateTime();
    formData.value.dates.start.date = '';
    formData.value.dates.start.time = '';
    tabbedSearchStore.updateStartDates('', '');
};

// Update form
const updateLocation = (value) => {
    formData.value.location = value;
    tabbedSearchStore.updateLocation(value);
    selectFieldAfterLocation();
    if (isMigrationActive) {
        checkMigrationVariant(
            formData.value.location.code,
            tabbedSearchStore.getAllData?.dates?.end?.date,
            migrationKey.value
        );
    }
    updateTerminal({
        id: '',
        name: '',
        airportId: '',
    });
};

const updateTerminal = (value) => {
    isTerminalDropDownOpened.value = false;
    formData.value.terminal = value;
    tabbedSearchStore.updateTerminal(value);
    selectFieldAfterTerminal(value);
};

const onStartDateChange = (value: any) => {
    formData.value.dates.start.date = value;
    if (datesArePast.value) {
        formData.value.dates.start.time = '';
    }
    if (entryDateTimeBeforeExitDateTime.value) {
        resetExitDateTime();
    } else {
        tabbedSearchStore.updateStartDates(value, formData.value.dates.start.time);
    }
    selectFieldAfterStartDate();
};

const onStartTimeChange = (value) => {
    formData.value.dates.start.time = value;
    if (entryDateTimeBeforeExitDateTime.value) {
        resetExitDateTime();
    } else {
        tabbedSearchStore.updateStartDates(formData.value.dates.start.date, value);
    }
    selectFieldAfterStartTime();
};

const onEndDateChange = (value: any) => {
    formData.value.dates.end.date = value;
    if (entryDateTimeBeforeExitDateTime.value) {
        formData.value.dates.end.time = '';
    } else {
        tabbedSearchStore.updateEndDates(value, tabbedSearchStore.getAllData.dates.end.time);
    }
    selectFieldAfterEndDate();
    if (isMigrationActive) {
        checkMigrationVariant(formData.value.location.code, value, migrationKey.value);
    }
};

const onEndTimeChange = (value) => {
    formData.value.dates.end.time = value;
    tabbedSearchStore.updateEndDates(tabbedSearchStore.getAllData.dates.end.date, value);
    selectFieldAfterEndTime();
};

const updateCustomers = (value) => {
    formData.value.customers = value;
    tabbedSearchStore.updateQuoteFormPassengers(value);
};

const updateRooms = (value) => {
    formData.value.rooms = value;
    tabbedSearchStore.updateRooms(value);
};

const updateDiscount = (value) => {
    formData.value.discount = value;
    tabbedSearchStore.updateDiscount(value);
};

// Helpers for moving throught the form as on Propel
const selectFieldAfterLocation = () => {
    nextTick(() => {
        if (isMobileView.value && showTerminalsSelector.value && !formData.value.terminal.id) {
            isTerminalDropDownOpened.value = true;
        } else if (
            (!isMobileView.value || (isMobileView.value && !showTerminalsSelector.value)) &&
            !formData.value.dates.start.date
        ) {
            isEntryDatePickerOpened.value = true;
        }
    });
};

const selectFieldAfterTerminal = (value) => {
    nextTick(() => {
        if (!value.id) return;
        if (isMobileView.value && !formData.value.dates.start.date) {
            isEntryDatePickerOpened.value = true;
        } else if (!isMobileView.value && !formData.value.customers.adult) {
            isCustomersSelectorOpened.value = true;
        }
    });
};

const selectFieldAfterStartDate = () => {
    nextTick(() => {
        if (!formData.value.dates.start.time) {
            isEntryTimePickerOpened.value = true;
        }
    });
};

const selectFieldAfterStartTime = () => {
    nextTick(() => {
        if (!formData.value.dates.end.date) {
            isExitDatePickerOpened.value = true;
        }
    });
};

const selectFieldAfterEndDate = () => {
    nextTick(() => {
        if (showRoomsSelector.value && !formData.value.rooms) {
            isRoomsDropDownOpened.value = true;
            return;
        }
        if (!formData.value.dates.end.time) {
            isExitTimePickerOpened.value = true;
        }
    });
};

const selectFieldAfterEndTime = () => {
    nextTick(() => {
        if (!isMobileView.value && showTerminalsSelector.value && !formData.value.terminal.id) {
            isTerminalDropDownOpened.value = true;
        } else if (
            ((!isMobileView.value && !showTerminalsSelector.value) || isMobileView.value) &&
            !formData.value.customers.adult
        ) {
            isCustomersSelectorOpened.value = true;
        }
    });
};

const submitForm = async (e) => {
    v$.value.$touch();
    if (!v$.value.$error) {
        const migrationCheck = await checkMigrationVariant(
            formData.value.location?.code,
            formData.value.dates?.end?.date,
            migrationKey.value
        );

        if (isMigrationActive() && migrationCheck.inMigratedVariant.value) {
            if (activeTab === TABS.AIRPORT_PARKING || (activeTab === TABS.PORT_PARKING && STORYBLOK_FOLDER === 'l4')) {
                redirectToParkingPropel(propelProductSKU);
            }
            if (activeTab === TABS.AIRPORT_LOUNGES) {
                redirectToLoungePropel(propelProductSKU);
            }
            return;
        }
        if (activeTab === TABS.AIRPORT_LOUNGES && STORYBLOK_FOLDER === 'l4') {
            redirectToLoungePropel(propelProductSKU);
            return;
        }

        emit('submitForm', { formData: formData.value, form: e });
    }
};

const searchFormClass = computed(() => [
    `is-${activeTab}`,
    { 'with-discount': showDiscount.value },
    { 'with-terminals': showTerminalsSelector.value },
    { 'with-airport': blok.showAirport },
    { 'with-hotel-toggler': showHotelToggler.value },
]);

onMounted(async () => {
    formKey.value += 1;
    if (isMigrationActive()) {
        await checkMigrationVariant(formData.value.location?.code, formData.value.dates?.end?.date, migrationKey.value);
    }
    if (datesArePast.value) {
        resetDates();
    }
});

watch(
    () => formData.value.location,
    (value) => {
        if (value?.code && activeTab !== TABS.PORT_PARKING) {
            tabbedSearchStore.updateLocation(value);
            airportInputKey.value += 1;
        }
    },
    { deep: true, immediate: true }
);

watch(
    () => airports.airportList,
    () => (airportInputKey.value += 1),
    { deep: true }
);

watch(
    () => activeTab,
    (oldVal, newVal) => {
        if (newVal === TABS.PORT_PARKING || oldVal === TABS.PORT_PARKING) {
            formData.value.location = { name: '', code: '', id: '' };
            tabbedSearchStore.updateLocation({ name: '', code: '', id: '' });
            airportInputKey.value += 1;
        }
    }
);
</script>

<style lang="postcss">
.search-form {
    @apply flex flex-wrap lg:items-end lg:grid lg:grid-cols-12 lg:grid-rows-2 gap-y-4 gap-x-2 lg:gap-2 p-6 md:p-8;
    &.with-hotel-toggler {
        grid-template-rows: auto 1fr 1fr;
    }
    &__control {
        @apply w-full;
        &--entryDate,
        &--exitDate,
        &--entryTime,
        &--exitTime {
            @media (max-width: theme('screens.lg')) {
                width: calc(50% - 4px);
            }
        }
        &--hotel-toggler {
            grid-area: 1 / 1 / span 1 / -1;
        }
        &--airport {
            grid-area: 1 / 1 / span 1 / span 4;
            .with-hotel-toggler & {
                grid-area: 2 / 1 / span 1 / span 4;
            }
        }
        &--entryDate {
            grid-area: 1 / 1 / span 1 / span 3;
            .with-airport & {
                grid-area: 1 / 5 / span 1 / span 2;
            }
            .is-airport-lounges &,
            .is-hotel-and-parking & {
                grid-area: 1 / 1 / span 1 / span 6;
            }
            .with-hotel-toggler.is-hotel-and-parking & {
                grid-area: 2 / 1 / span 1 / span 6;
            }
            .with-airport.is-airport-lounges &,
            .with-airport.is-hotel-and-parking & {
                grid-area: 1 / 5 / span 1 / span 4;
            }
            .with-hotel-toggler.with-airport.is-hotel-and-parking & {
                grid-area: 2 / 5 / span 1 / span 4;
            }
        }
        &--entryTime {
            grid-area: 1 / 4 / span 1 / span 3;
            .with-airport & {
                grid-area: 1 / 7 / span 1 / span 2;
            }
            .is-airport-lounges & {
                grid-area: 1 / 7 / span 1 / -1;
            }
            .is-airport-lounges.with-airport & {
                grid-area: 1 / 9 / span 1 / -1;
            }
        }
        &--exitDate {
            grid-area: 1 / 7 / span 1 / span 3;
            .with-airport & {
                grid-area: 1 / 9 / span 1 / span 2;
            }
            .is-hotel-and-parking & {
                grid-area: 1 / 7 / span 1 / -1;
            }
            .with-hotel-toggler.is-hotel-and-parking & {
                grid-area: 2 / 7 / span 1 / -1;
            }
            .with-airport.is-hotel-and-parking & {
                grid-area: 1 / 9 / span 1 / -1;
            }
            .with-hotel-toggler.with-airport.is-hotel-and-parking & {
                grid-area: 2 / 9 / span 1 / -1;
            }
        }
        &--exitTime {
            grid-area: 1 / 10 / span 1 / -1;
            .with-airport & {
                grid-area: 1 / 11 / span 1 / -1;
            }
        }
        &--terminal {
            grid-area: 2 / 1 / span 1 / span 5;
            .with-discount & {
                grid-area: 2 / 1 / span 1 / span 3;
            }
        }
        &--guests {
            grid-area: 2 / 1 / span 1 / span 10;
            .with-terminals & {
                grid-area: 2 / 6 / span 1 / span 5;
            }
            .with-discount & {
                grid-area: 2 / 1 / span 1 / span 5;
            }
            .with-terminals.with-discount & {
                grid-area: 2 / 4 / span 1 / span 3;
            }
        }
        &--rooms {
            grid-area: 2 / 1 / span 1 / span 10;
            .with-discount & {
                grid-area: 2 / 1 / span 1 / span 5;
            }
            .with-hotel-toggler & {
                grid-area: 3 / 1 / span 1 / span 10;
            }
            .with-hotel-toggler.with-discount & {
                grid-area: 3 / 1 / span 1 / span 5;
            }
        }
        &--discount {
            grid-area: 2 / 6 / span 1 / span 5;
            .with-terminals & {
                grid-area: 2 / 7 / span 1 / span 4;
            }
            .with-hotel-toggler & {
                grid-area: 3 / 6 / span 1 / span 5;
            }
            .with-hotel-toggler.with-terminals & {
                grid-area: 3 / 7 / span 1 / span 4;
            }
        }
        &-label,
        .airport-search__label,
        .date-time-picker__label,
        .search-input__label,
        .customers-selector-wrapper__label {
            @apply flex mb-2 font-semibold text-wl-base-dark-grey;
        }
    }
    &.with-hotel-toggler .search-submit {
        grid-area: 3 / 11 / span 1 / span 2;
    }
    .search-submit {
        grid-area: 2 / 11 / span 1 / span 2;
    }
}

.search-submit {
    @apply bg-[#05AA02] h-10 uppercase text-white text-base min-w-40 rounded-lg w-full tracking-wide font-bold transition-colors;
    &:disabled {
        @apply opacity-70 cursor-not-allowed;
    }
}
</style>
