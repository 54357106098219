<template>
    <div class="date-time-picker__wrapper">
        <label class="date-time-picker__label" :for="inputName">
            {{ label }}
        </label>
        <div :class="timePickerClasses">
            <div
                v-on-click-outside="onTimePickerClickOutside"
                class="date-time-picker__time-picker"
                tabindex="0"
                @click="timeDropdownOpened ? closeTimePicker() : openTimePicker()"
                @keydown.enter="timeDropdownOpened ? closeTimePicker() : openTimePicker()"
                @focus="onFocusEvent('time')"
            >
                <NuxtIcon name="ri:time-line" size="20" class="date-time-picker__icon" />
                <input
                    v-if="time"
                    :id="inputName"
                    readonly
                    class="date-time-picker__value lowercase"
                    :value="formattedTime"
                />
                <span v-else class="date-time-picker__placeholder">
                    {{ $t('searchWidget.time') }}
                </span>
                <Backdrop v-if="timeDropdownOpened" v-model="hasBackdrop">
                    <TimePicker
                        v-scroll-lock="timeDropdownOpened"
                        :value="time"
                        :date="date"
                        :min-time="minTime"
                        :time-zone="timeZone"
                        :time-format="timeFormat"
                        :step="Number(timePickerStep)"
                        :label-mobile="label"
                        :label-desktop="labelDesktop"
                        :is-entry="isEntry"
                        @on-select="onTimeChange"
                        @close="closeTimePicker"
                    />
                </Backdrop>
            </div>
        </div>
        <ErrorMessage v-if="displayError" :message="errorMessage" class="date-time-picker__error-message" />
    </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';

import { vOnClickOutside, vScrollLock } from '@vueuse/components';

type Time = {
    name: string;
    value: string;
};

import TimePicker from './TimePicker.vue';
import Backdrop from '../Common/Backdrop.vue';
import ErrorMessage from '../Common/ErrorMessage.vue';

import { format, DATE_TIME_FORMATS, guessTimezone, parse } from '../../date-utilities';

import { useIsMobileView } from '~ui/composables/isMobileView';
import screens from '#tailwind-config/theme/screens';

const { isMobileView } = useIsMobileView(screens.md);

const props = defineProps({
    date: {
        type: String as PropType<string>,
        default: '',
    },

    time: {
        type: String,
        default: '',
    },

    minTime: {
        type: [String, Date] as PropType<string | Date>,
        default: () => new Date(),
    },

    error: {
        type: Boolean,
        default: false,
    },

    errorMessage: {
        type: [String, Array] as PropType<string | string[] | VueI18n.TranslateResult>,
        default: '',
    },

    timePickerStep: {
        type: String,
        default: '60', // 60 minutes
    },

    timeZone: {
        type: String,
        default: 'Europe/London',
    },

    dateFormat: {
        type: String,
        default: DATE_TIME_FORMATS.month_day_year,
    },

    label: {
        type: String,
        default: '',
    },

    labelDesktop: {
        type: String,
        default: '',
    },

    timeFormat: {
        type: String,
        default: 'GB',
    },

    isEntry: {
        type: Boolean,
        default: false,
    },

    dropdownOpened: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['focus', 'blur', 'time-change', 'update:dropdownOpened']);

const timeDropdownOpened = computed({
    get(): boolean {
        return props.dropdownOpened;
    },
    set(val: boolean) {
        emit('update:dropdownOpened', val);
    },
});

const displayError = computed(() => props.error && !timeDropdownOpened.value);

const hasBackdrop = computed({
    get(): boolean {
        return timeDropdownOpened.value && isMobileView.value;
    },
    set(): void {
        closeTimePicker();
    },
});

const guessTimezone = computed(() => guessTimezone());

const formattedTime = computed(() => {
    if (props.timeFormat === 'GB') {
        const _date = parse(props.time, 'HH:mm', new Date());
        return format(_date, DATE_TIME_FORMATS.time);
    }
    return props.time;
});

const timePickerClasses = computed(() => [
    'date-time-picker',
    { 'date-time-picker--error': displayError.value },
    {
        'date-time-picker--active': timeDropdownOpened.value,
    },
]);

const inputName = props.isEntry ? 'entryTime' : 'exitTime';

const onFocusEvent = (type: string) => {
    emit('focus');
};

const onTimeChange = (time: Time) => {
    timeDropdownOpened.value = false;
    closeTimePicker();
    emit('time-change', time.value);
};

const openTimePicker = () => {
    timeDropdownOpened.value = true;
    emit('focus');
};

const closeTimePicker = () => {
    timeDropdownOpened.value = false;
    emit('blur');
};

const onTimePickerClickOutside = () => {
    if (timeDropdownOpened.value) {
        timeDropdownOpened.value = false;
    }
};
</script>

<style lang="postcss" scoped>
.date-time-picker__wrapper {
    @apply relative w-full;
}

.date-time-picker {
    @apply relative flex rounded-lg bg-white h-10 border border-wl-base-soft-gray cursor-pointer transition-colors duration-300;

    @screen lg {
        @apply h-10;
    }

    &.date-time-picker--active {
        @apply outline outline-4 outline-wl-brand-pr;
    }

    &.date-time-picker--error {
        @apply border-red-500;
    }

    &__label {
        @apply block mb-2 font-semibold text-wl-base-dark-grey;
    }
    &__icon {
        @apply mr-2 text-wl-base-mid-gray -mb-0.5;
    }
}

.date-time-picker__time-picker {
    @apply relative px-3 flex items-center flex-grow;

    @screen lg {
        @apply pr-0;
    }
}

.date-time-picker__placeholder {
    @apply text-base text-wl-base-mid-gray;
}

.date-time-picker__value {
    @apply text-base focus:outline-none w-full cursor-pointer;
}

.date-time-picker:deep(.time-picker) {
    @screen lg {
        top: calc(100% + 12px);
        left: 0;
    }
}

.date-time-picker__error-message {
    @apply absolute right-0;

    top: calc(100% + 0.5rem);
    width: auto !important;

    @screen lg {
        @apply left-0 right-auto;
    }
}
</style>
